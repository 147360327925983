import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PartnersApiService {

  private _host = environment.api_partner_url;

  private _options: any = {
    headers: new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }),
    withCredentials: true
  };

  constructor(
    private _http: HttpClient,
  ) {
  }

  /**
   * POST /client/partner/
   *
   * @param data
   */
  registerPartner(data: object): Observable<any> {
    return this._http.post(`${this._host}/client/partner/`, data, this._options);
  }

  /**
   * POST /client/partner/sign_in
   *
   * @param data
   */
  loginPartner(data: object): Observable<any> {
    return this._http.post(`${this._host}/client/partner/sign_in`, data, this._options);
  }

  /**
   * POST /client/partner/sign_in
   *
   * @param data
   */
  resetPasswordPartner(data: object): Observable<any> {
    return this._http.post(`${this._host}/client/partner/password`, data, this._options);
  }

  /**
   * POST /client/partner/track_stag
   * @param data
   */
  trackStag(data: object): Observable<any> {
    return this._http.post(`${this._host}/client/partner/track_stag`, data, this._options);
  }

}
