export class ValidationPatterns {

  /**
   * Pattern for email validation
   */
  public static readonly email = /^[0-9A-Za-z][-0-9A-Za-z.!#$%&'*+/=?^_{|}~]{0,}[0-9A-Za-z]@[-0-9A-Za-z]{2,}(\.[0-9A-Za-z]{2,})+$/;

  /**
   * Pattern for password validation
   */
  public static readonly password = /.{8,}/;

  /**
   * Pattern for phone number validation
   */
  public  static readonly phoneNumber = /(^[+]?[0-9]+)$/;

  /**
   * Pattern for cvv validation
   */
  public static readonly cvv = /^[0-9]{3,4}$/;

  /**
   * Pattern for creditCard validation
   */
  public static readonly creditCard = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;

}
